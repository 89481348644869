.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/**/
 /* The switch - the box around the slider */
 .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 2vh;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0062ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.navSteps {
  /* display: inline-flex; */
  margin-right: auto;
  width: fit-content;
  flex: left;
  margin-left: 4vw;
  font-size: calc(10px + 1vmin);
}

.user {
  /* display: inline-flex; */
  margin-left: auto;
  width: fit-content;
  flex: right;
  margin-right: 4vw;
  font-size: calc(10px + 1vmin);
}

.bodyHeader {
  margin-top: 1.5vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5vh;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 23vw;
  margin-top: 2vh;
  font-size: calc(10px + 2vmin);
  margin-right: auto;
}

.searchInput{
  padding: 0px 6px 0px 6px;
  width: 20vw;
  height: 28.5px;
}

.searchSubmit{
  width: 32.5px;
  height: 32.5px;
  margin-left: 10px;
}

.eventsTitle {
  margin-top: 1.5vh;
  font-size: calc(10px + 2vmin);
  margin-right: auto;
  justify-content: left;
  width: fit-content;
}

.toggleTitle {
  /* margin-top: 2.5vh; */
  /* font-size: calc(2vmin); */
  font-size: calc(10px + 2vmin);
  margin-top: 1.5vh;
  margin-right: 10px;
}
