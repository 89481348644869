@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";

.dark {
  color: #a8a8a8;
}

.disambiguation {
  @include disambiguation();
}

.text {
  &-small {
    @include body3();
  }

  &-regular {
    @include body2();
  }

  &-large {
    @include body1();
  }
}

.h1 {
  @include heading1();
}

.h2 {
  @include heading2();
}

.h3 {
  @include heading3();
}

.h4 {
  @include heading4();
}

.h5 {
  @include heading5();
}

.h6 {
  @include heading6();
}

.h7 {
  @include heading7();
}

.meta {
  &-small {
    @include meta1();
  }

  &-regular {
    @include meta2();
  }
}
