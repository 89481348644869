.container {
    display: flex;
    flex-direction: row;
}

.header {
    display: flex;
    flex-direction: row;
    height: auto;
    padding-bottom: 10px;
}

.header>* {
    height: auto;
    margin: 0;
}

.headerButtons {
    margin-left: auto;
    margin-right: -1.8vw;
    display: flex;
}

.headerButtons>* {
    margin-right: 5px;
    width: 26px;
    height: 26px;
    border: 0;
    background-color: #0062ff;
    font-size: 14px;
}

.headerButtons>*:hover {
    background-color: lightblue;
    cursor: pointer;
}

.headerButtons>:last-child{
    background-color: #ae1820;
}

.headerButtons>:last-child:hover{
    background-color: lightcoral;
}

.signatures {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 65vw;
    height: 70vh;
    margin-left: auto;
    overflow-y: scroll;
}

.stats {
    padding-left:12px !important;
    padding-right:12px !important;
    width: 22vw;
    height: 42.2vh;
    background-color: #1D1F20;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.Break {
    width: 99%;
}

.classification {
    padding-top: 12px;
    padding-left:12px;
    padding-right:12px;
    width: 22vw;
    height: 16vh;
    background-color: #1D1F20;
    display: flex;
    flex-direction: column;
}

.classificationInputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.classificationInput {
    width: 14vw;
    height: 24px;
    text-align: right;
    // padding: 0;
}

.classificationSubmit {
    margin-left: 5px;
    width: 5vw;
    height: 24px;
    background-color: #0062ff;
    border: 0;
    cursor: pointer;
}

.classificationHistory {
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 8px;
    border-top: 1px solid #797164;
    height: 10vh;
    overflow-y: scroll;
    height: 24vh;
    font-size: 14px;
}

ul {
    padding-left: 0px !important;
}

.classificationRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgba(121, 113, 100, 0.44);
}

.signaturePreview {
    height: 200px;
    width: 200px;
}

.clickablePreview {
    padding: 0px;
}

.enlargedImage {
    width: 800px;
    height: 600px;
    margin-left: 16px;
    margin-top: -44px;
}

.enlargedModal {
    width: 900px;
    height: 620px;
    margin-top: 4vh;
    margin-left: 12vw;
    padding-bottom: 30px;
    // padding-top: 18px;
    // padding-bottom: 18px;
}

.arrowRight {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 4px;
}

.arrowLeft {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -26px;
}

@import "../../styles/variables";
@import "../../styles/colors";

.Wrapper {
  flex: 1 0 350px;
  display: flex;
  flex-flow: column;
//   padding: 20px;
  border-bottom: 1px solid $mine-shaft;
}

.Row {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: auto;
  height: 18px;

  &:not(:last-child) {
    margin-bottom: $xx-small;
  }
}

.Row>* {
    margin-top: 0;
    margin-bottom: 0;
}

.Text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
  font-family: "Inter", sans-serif;
}

.Link {
  text-decoration: underline;
  text-underline-position: under;
}

.Wrap {
  white-space: pre-wrap;
}

.Divider {
  width: 100%;
  border: 0;
  border-top: 1px solid $mine-shaft;
  margin: $small 0 $medium;
}

@include breakpoint(medium) {
  .Wrapper {
    background-color: $dark-mine-shaft;
    padding: $medium $x-large $medium $xx-large;
    border-left: 1px solid $mine-shaft;
    border-right: 1px solid $mine-shaft;
  }
}

@include breakpoint(xlarge) {
  .Wrapper {
    border: 0;
  }
}
