@import "../../styles/colors";

.Loading {
  &Page {
    height: 100vh;
    background-color: $cod-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.Content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: $white;
}

.Logo {
  color: $blue-ribbon;
  margin-bottom: 20px;
  animation: pulse 1.2s linear infinite;
}

.Title {
  margin-bottom: 8px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}
