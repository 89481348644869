$mobile-header-height: 44px;
$mobile-header-breadcrumbs-height: 130px;
$mobile-footer-height: 62px;
$mobile-title-height: 104px;

$desktop-header-height: 70px;
$desktop-sidebar-width: 78px;

$tabs-height-desktop: 53px;

$filters-height-desktop: 108px;

// valid font weights, anything else should not be used in the app
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;

// breakpoints
$breakpoints: (
  xsmall: 320px,
  small: 375px,
  medium: 768px,
  /* large is iPad Pro portrait orientation and smaller*/
    large: 1025px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1680px,
  xxxxlarge: 1920px
);

// generate our breakpoint mixins
@mixin breakpoint($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @media screen and (min-width: $size) {
    @content;
  }
}

// font disambiguation
@mixin disambiguation() {
  font-feature-settings: "ss02" 1;
}

// spacing
$xx-small: 5px;
$x-small: 10px;
$small: 15px;
$medium: 20px;
$large: 25px;
$x-large: 30px;
$xx-large: 40px;

@mixin cubic-transition($property) {
  transition-property: $property;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0, 1.08, 1, 1);
}
