@import "../../styles/variables";
@import "../../styles/colors";

$input-height-regular: 40px;
$input-height-small: 32px;
$bottom-border-height: 2px;

.Wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.Input {
  width: 100%;
  height: $input-height-regular;
  border-style: none;
  padding: $x-small;
  color: $white;
  background-color: $dark-mine-shaft;
  outline-style: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  border-bottom: 3px solid $gray;

  &:focus,
  &:hover {
    border-bottom-color: $white;
  }

  &::placeholder {
    color: $dove-grey;
  }

  &:disabled {
    color: $dove-grey;
    cursor: not-allowed;
    &:focus,
    &:hover {
      border-bottom-color: $gray;
    }
  }

  &Error {
    border-bottom-color: $error;
    &:focus,
    &:hover {
      border-bottom-color: $error;
    }
  }

  &Success {
    border-bottom-color: $success;
    &:focus,
    &:hover {
      border-bottom-color: $success;
    }
  }

  &Small {
    padding: $xx-small $x-small;
    height: $input-height-small;
  }
}

.Darker {
  color: $dove-grey;
}

.Label {
  margin: 0 0 8px;
}

.Disabled {
  opacity: 0.5;
}

.Message {
  color: $white;
  font-size: 10px;
  line-height: 16px;
  margin-top: 2px;

  &Success {
    color: $success;
  }

  &Error {
    color: $error;
  }
}

/* Chrome, Safari, Edge, Opera */
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInput[type="number"] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}
