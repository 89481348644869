@import "./variables";

@mixin body3() {
  font-size: 0.75rem; // 12px
  line-height: 1.125rem;
  letter-spacing: 0.14px;
}

@mixin body2() {
  font-size: 0.875rem; // 14px
  line-height: 1.25rem;
  letter-spacing: 0.16px;
}

@mixin body1() {
  font-size: 1rem; // 16px
  line-height: 1.375rem;
  letter-spacing: 0.18px;
}

@mixin heading1() {
  font-size: 3.125rem;
  line-height: 3.625rem;
  font-weight: $font-medium;
  letter-spacing: -0.75px;
}

@mixin heading2() {
  font-size: 2.625rem;
  line-height: 3.125rem;
  font-weight: $font-medium;
  letter-spacing: -0.5px;
}

@mixin heading3() {
  font-size: 2rem;
  line-height: 2.125rem;
  font-weight: $font-semibold;
  letter-spacing: 0;
}

@mixin heading4() {
  font-size: 1.625rem;
  line-height: 2.125rem;
  font-weight: $font-semibold;
  letter-spacing: 0;
}

@mixin heading5() {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: $font-semibold;
  letter-spacing: 0;
}

@mixin heading6() {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: $font-semibold;
  letter-spacing: 0.18px;
}

@mixin heading7() {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: $font-semibold;
  letter-spacing: 0.16px;
}

@mixin meta1() {
  font-size: 0.625rem;
  line-height: 1.125rem;
  font-weight: $font-semibold;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@mixin meta2() {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: $font-semibold;
  letter-spacing: 3px;
  text-transform: uppercase;
}
