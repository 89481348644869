/* stylelint-disable */
@import "./styles/colors";
@import "./styles/variables";

html {
  font-size: 16px;
}

html * {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: $cod-gray;
}

.mainContainer {
  min-height: 100vh;
  margin-left: 18.75vw;
  background-color: $cod-gray;
  padding: 2rem 3rem;
}

a {
  color: $white;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: $font-regular;
  margin: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}
