@import "../../styles/colors";
@import "../../styles/variables";

.primary {
  color: $white;
  background-color: $blue-ribbon;

  &:hover,
  &:focus {
    background-color: $science-blue;
  }

  &:active {
    background-color: $catalina-blue;
  }
}

.secondary {
  color: $white;
  background-color: $gray;

  &:hover {
    background-color: $emperor;
  }

  &:active {
    background-color: $mine-shaft;
  }
}

.tertiary {
  color: $cod-gray;
  background-color: $white;

  &:hover,
  &:focus {
    background-color: $silver;
  }

  &:active {
    background-color: $gray;
  }
}

.important {
  color: $white;
  background-color: $alizarin-crimson;

  &:hover {
    background-color: $tamarillo-red;
  }

  &:active {
    background-color: $dark-tan-red;
  }
}

.button {
  position: relative;
  height: 40px;
  padding: 0 20px;
  border: 0;
  cursor: pointer;
  transition: background-color ease 300ms;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:focus {
    outline: 1px solid $white;
    outline-offset: 1px;
  }

  &.visuallyDisabled,
  &:disabled {
    cursor: default;
    background-color: $mine-shaft;
    color: $silver;
  }
}

.large {
  height: 48px;
  font-size: 16px;
  padding: 0 28px;
}

.small {
  height: 32px;
  font-size: 12px;
}

.text {
  font-weight: $font-semibold;

  &:focus {
    outline: 1px solid $white;
    outline-offset: 1px;
  }
}

.responsive {
  padding: 6.5px 20px;

  .text {
    font-size: 0.75rem;
  }
}

.IconWrapper {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &small {
    width: 14px;
    height: 14px;
  }
  &large {
    width: 20px;
    height: 20px;
  }
}

.withIcon {
  padding-right: 16px;

  &small {
    padding-right: 14px;
  }
  &large {
    padding-right: 20px;
  }
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;

  .IconWrapper {
    margin: 0;
  }

  &small {
    width: 32px;
    height: 32px;
  }
  &large {
    width: 48px;
    height: 48px;
  }
}

.textLink {
  color: $link-primary;
  font-weight: $font-semibold;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;
  line-height: 20px;
  transition: 0.3s color ease-in-out;

  &:hover,
  &:focus {
    color: $link-primary-hover;
  }
  &:focus {
    color: $link-primary-focus;
    outline: 1px solid $link-primary-focus;
  }

  &:active {
    color: $link-primary-active;
  }

  &Secondary {
    color: $link-secondary;
    &:hover {
      color: $link-secondary-hover;
    }
    &:focus {
      color: $link-secondary-focus;
      outline: 1px solid $link-secondary-hover;
    }

    &:active {
      color: $link-secondary-active;
    }
  }

  &Underline {
    border-bottom: 1px solid;
    border-bottom-color: inherit;
  }

  &:disabled {
    color: $link-inactive;
    background-color: transparent;
  }
  .IconWrapper {
    margin-left: 5px;
  }
}
