.Screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
}

.Content {
  display: flex;
  width: 100%;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
}

.Title {
  margin-bottom: 8px;
  text-align: center;
}

.Subtitle {
  text-align: center;
}

.Button {
  margin-top: 22px;
  width: 160px;
}
