// Use names for colours based on the Name that Color app here:
// http://chir.ag/projects/name-that-color/

$white: #ffffff;
$black: #000000;

$wild-sand: #F4F4F4; // gray-10
$alto-grey: #e0e0e0;
$silver: #c6c6c6; // gray-30
$silver-chalice: #a8a8a8; // gray-40
$gray: #8d8d8d; // gray-50
$dove-grey: #6f6f6f; // gray-60
$emperor: #525252; // gray-70
$mine-shaft: #393939; // gray-80
$dark-mine-shaft: #262626; // gray-90
$cod-gray: #161616; // gray-100
$almost-black: #101010;

$dodger-blue: #408bfd; // blue-50
$blue-ribbon: #0062ff; // blue-60
$denim: #1B6AE1;
$science-blue: #064ada; // blue-70
$ocean-blue: #0630ad; // blue-80
$catalina-blue: #061f81; // blue-90

$cornflower-lilac: #ffa4a9; //red 30
$alizarin-crimson: #da1e28; // red-60
$tamarillo-red: #a51920;
$dark-tan-red: #750e13;

$macaroni-and-cheese: #ffba80; //orange 30
$tan-hide: #f69148; // orange-50
$christine: #f37113; // orange-60

$emerald: #56d679; //green 30
$eucalyptus: #24a148; // green-50
$forest-green: #1a8038; // green-60

$viking: #60cadf;

$mauve: #d0b0ff; //purple 30
$electric-violet: #8a3ffd; //purple 60

$link-primary: $dodger-blue;
$link-primary-hover: $science-blue;
$link-primary-focus: $blue-ribbon;
$link-primary-active: $ocean-blue;
$link-inactive: $dove-grey;

$link-secondary: $white;
$link-secondary-hover: $silver;
$link-secondary-focus: $silver-chalice;
$link-secondary-active: $gray;

$error: $alizarin-crimson; // Error
$warning: $christine; // Warning
$success: #1baa55; // Success
